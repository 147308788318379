import React from 'react'
import Procuct from '../images/product/product-suspension.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const SuspensionParts = () => {
  return (
    <>
    <Navbar />
   <div id="products" class="wrapper pb-0 mb-5">
         <div class="container">
           <div class="row">
             <div class="col-sm-12 text-center mb-5">
               <h3 class="text-black">Find My Part Now</h3>
             </div>
             <Search />
           </div>
         </div>
       </div>
   <section id="testimonial" class="testimonial_wrapper wrapper">
   
       <div class="container">
           <div class="row">
               <div class="col-sm-12 text-center mb-4">
                   <h3 class="text-black">Suspension Parts</h3>
               </div>
           </div>
           <div class="row align-items-center">
               <div class="col-xl-3 col-md-4 col-sm-6">
                   <div class="card text-center">
                       <img src={Procuct} class="img-fluid" />
                       {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                       <p>CEO of Prime IT</p>  */}
                   </div>
               </div>
               <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                   {/* <h4>Awesome Work</h4> */}
                   <p>The suspension system is basically the series of parts which support the vehicle as it moves on the road. These are components which give your car the ability to turn as you rotate the steering wheel and to absorb shocks when you drive over bumps and potholes.</p>
                   
               </div>
           </div>
       </div>
   </section> 
   <Assistant />
   <Brands />
   <Testimonial />
   <WhyChooseUs />
   <Footer />
   </>
  )
}

export default SuspensionParts