import Logo from './images/logo2.png'
import Part1 from './images/top-banner-img/used-engine-1.png'
import Part2 from './images/top-banner-img/used-engine-2.png'
import Part3 from './images/top-banner-img/top-right-img-1.png'
import Procuct1 from './images/product/product-engine.jpg'
import Procuct2 from './images/product/transmission.jpg'
import Procuct3 from './images/product/transfer-case.jpg'
import Procuct4 from './images/product/rear-axle.jpg'
import Procuct5 from './images/product/product-abs.jpg'
import Procuct6 from './images/product/product-drive-shaft.jpg'
import Procuct7 from './images/product/product-temperature-control-unit.jpg'
import Procuct8 from './images/product/product-cylinder-head.jpg'
import Procuct9 from './images/product/Steering Colunm-1.jpg'
import Procuct10 from './images/product/product-module.jpg'
import Procuct11 from './images/product/exhaust-manifold.jpg'
import Procuct12 from './images/product/product-rack-pinion.jpg'
import Feature1 from './images/product/categories-engine-accessories.jpg'
import Feature2 from './images/product/categories-wheels-and-tires.jpg'
import Feature3 from './images/product/product-suspension.jpg'
import Feature4 from './images/product/categories-brake-parts.jpg'
import Feature5 from './images/product/categories-lighting.jpg'
import Feature6 from './images/product/steering-accessories.jpg'
import Review from './images/google-reviews.png'
import Hand from './images/handshake.png'
import Return from './images/return-box.png'
import p1 from './images/gallery/top-category-brakes-suspension-and-steering.jpg'
import p2 from './images/gallery/p1.jpg'
import p3 from './images/gallery/download.jpeg'
import p4 from './images/gallery/SBF_wheel_hub_assembly.jpg'
import p5 from './images/gallery/download.jpeg'
import p6 from './images/gallery/OE Replica Wheels.jpg'
import p7 from './images/gallery/Wheel Covers, Caps & Simulators.jpg'
import p8 from './images/gallery/Spare Tire Accessories.jpg'
import p9 from './images/gallery/Wheels.jpg.jpg'
import Footer from "./sections/Footer";
import Navbar2 from "./sections/Navbar2";
import Navbar from "./sections/Navbar";
// import Navbar from "./sections/Navbar";
import AutoPartsSearchBar from './sections/AutoPartsSearchBar'
import Call from './sections/Call'
import Search from './Search'

const Home = () => {
  return (
    <>
       <Navbar />
       <Call />
    {/* <header class="header_wrapper">
        <nav class="navbar navbar-expand-lg">
            <div class="container">
              <a class="navbar-brand" href="#">
                  <img src={Logo} class="img-fluid" />
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                <i class="fas fa-stream navbar-toggler-icon"></i>
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul class="navbar-nav  menu-navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#home">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#about">Search Parts</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#services">Testimonial</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#team">About Us</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#gallery">Contact Us</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#testimonial">Click to Call</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#blog"></a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </nav>
    </header> */}

    
    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                    <h1>Always Save</h1>
                        <h3>Upto 85%</h3>
                        <p>From over 10 million auto parts, with competitive prices on the best products the industry has to offer.</p>
                        <a href="tel:+16266999232" class="main-btn mt-4 fill-btn">Call Us !<span className='d-none'>(626) 699-9232</span></a>
                        <a href="#products" class="main-btn mt-4 ms-3">Shop Products</a>
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        <div class="top-right-sec">
                            <div class="animate-img">
                                <img class="aimg1" src={Part1} />
                                <img class="aimg2" src={Part2} />
                            </div>
                            <img class="img-fluid ms-xl-5" src={Part3} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="products" class="wrapper pb-0">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
             <Search />
            </div>
          </div>
        </div>
        <div id='products' class="wrapper pb-0">
            <div class="container">
                <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Our Products</h3>
                </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct1} />
                            </div>
                            <div>
                                <h4>Engines</h4>
                                <a href='/engines' class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct2} />
                            </div>
                            <div>
                                <h4>Transmissions</h4>
                                <a href="/transmissions" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct3} />
                            </div>
                            <div>
                                <h4>Transfer Case</h4>
                                <a href="/transfercase" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct4} />
                            </div>
                            <div>
                                <h4>Rear Axle</h4>
                                <a href="/rearaxle" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct5} />
                            </div>
                            <div>
                                <h4>ABS</h4>
                                <a href="/abs" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct6} />
                            </div>
                            <div>
                                <h4>Drive Shaft</h4>
                                <a href="/driveshaft" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct7} />
                            </div>
                            <div>
                                <h4>Temperature Control Unit</h4>
                                <a href="/temperaturecontrolunit" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct8} />
                            </div>
                            <div>
                                <h4>Cylinder Head</h4>
                                <a href="/cylinderhead" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct9} />
                            </div>
                            <div>
                                <h4>Steering Colunm</h4>
                                <a href="/steeringcolumn" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct10} />
                            </div>
                            <div>
                                <h4>Car AC Compressor</h4>
                                <a href="/caraccompressor" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct11} />
                            </div>
                            <div>
                                <h4>Exhaust Manifold</h4>
                                <a href="/exhaustmanifold" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img src={Procuct12} />
                            </div>
                            <div>
                                <h4>Rack & Pinion</h4>
                                <a href="/rackpinion" class="main-btn mt-2 sec-btn">Shop Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section id="about" class="about_wrapper wrapper">
        <div class="container">
            <div class="row">
            <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Shop by Categories</h3>
                </div>
                <div class="col-md-6 mb-mb-0 mb-5">
                    <div class="position-relative">
                        <h4>Brakes, Suspension & Steering</h4>
                        <div className='boxes'>
                        <img decoding="async" src={p1} class="img-fluid" />
                        </div>
                        
                        {/* <img decoding="async" src="./images/about/about-img2.png" class="about-animate" /> */}
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-start">
                    
                    <div class="mt-5 card">
                        <div class="about-clinic">
                            <img src={p2} class="img-fluid"  />
                            <h4>Shocks, Struts & Components</h4>
                        </div>
                        <div class="about-clinic">
                            <img src={p3} class="img-fluid"  />
                            <h4>Wheels</h4>
                        </div>
                        <div class="about-clinic">
                        <img src={p4} class="img-fluid"  />
                            <h4>Shocks, Struts & Components</h4>
                        </div>
                        <div class="about-clinic">
                        <img src={p5} class="img-fluid"  />
                            <h4>Shocks, Struts & Components</h4>
                        </div>
                        <span class="line-1"></span>
                        <span class="line-2"></span>
                    </div>
                    <a href="/BrakesSuspensionSteering" class="main-btn fill-btn">See All</a>
                </div>
            </div>
        </div>
        
    </section>


    <section id="about" class="about_wrapper wrapper">
        <div class="container">
            <div class="row">
            {/* <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Shop by Categories</h3>
                </div> */}
                <div class="col-md-6 mb-mb-0 mb-5">
                    <div class="position-relative">
                        <h4>Wheels</h4>
                        <div className='boxes'>
                        <img decoding="async" src={p9} class="img-fluid" />
                        </div>
                        
                        {/* <img decoding="async" src="./images/about/about-img2.png" class="about-animate" /> */}
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-start">
                    
                    <div class="mt-5 card">
                        <div class="about-clinic">
                            <img src={p6} class="img-fluid"  />
                            <h4>OE Replica Wheels</h4>
                        </div>
                        <div class="about-clinic">
                            <img src={p7} class="img-fluid"  />
                            <h4>Wheel Covers, Caps & Simulators</h4>
                        </div>
                        <div class="about-clinic">
                        <img src={p8} class="img-fluid"  />
                            <h4>Spare Tire Accessories</h4>
                        </div>
                        <div class="about-clinic">
                        <img src={p5} class="img-fluid"  />
                            <h4>Shocks, Struts & Components</h4>
                        </div>
                        <span class="line-1"></span>
                        <span class="line-2"></span>
                    </div>
                    <a href="/wheels" class="main-btn fill-btn">See All</a>
                </div>
            </div>
        </div>
        
    </section>

     
   

    
    <section id="services" class="services_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3>Featured Categories</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature1} />
                        </div>
                        <div>
                            <h4>Engine Accessories</h4>
                            <p>Engine Block</p>
                            <p>Engine Core</p>
                            <p>Engine Cover</p>
                            <a href="/engineaccessories" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature2} />
                        </div>
                        <div>
                            <h4>Wheels</h4>
                            <p>Wheel Bearing</p>
                            <p>Wheel Screws</p>
                            <p>Seals & Hubs</p>
                            <a href="/wheels" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature3} />
                        </div>
                        <div>
                            <h4>Suspension Parts</h4>
                            <p>Rear Suspension</p>
                            <p>Locating Arm</p>
                            <p>Trailing Arm</p>
                            <a href="/suspensionparts" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature4} />
                        </div>
                        <div>
                            <h4>Brake Parts</h4>
                            <p>Brake Tools</p>
                            <p>Brake Fluid</p>
                            <p>Emergency Brake System</p>
                            <a href="/brakeparts" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature5} />
                        </div>
                        <div>
                            <h4>Lighting</h4>
                            <p>Bulbs</p>
                            <p>Headlights</p>
                            <p>Light Bars</p>
                            <a href="/lighting" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img src={Feature6} />
                        </div>
                        <div>
                            <h4>Steering Accessories</h4>
                            <p>Steering Wheel Knob</p>
                            <p>Power Steering Pump</p>
                            <p>Steering Wheel Cover</p>
                            <a href="/steeringacce" class="main-btn mt-4">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Shop by Popular Parts</h3>
                </div>
            </div>
            <div class="container">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">Axle Assembly</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Brake Disc and Pad Kit</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Bumper</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Bumper Cover</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Catalytic Converter </div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Control Arm</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Control Arm Kit</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Cooling Fan Assembly</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Door Lock Actuator</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Driveshaft</div>
    </div>
  </div>

  <div class="container mt-3">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">Exterior Door Handle</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Fender</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Timing Chain Kit</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Fender Liner</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Fog Light</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Fuel Pump</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Fuel Tank</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Grille Assembly</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Headlight</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Hood</div>
    </div>
    
  </div>
</div>
<div className="parts">
<a href="#" class="main-btnsss">See All</a>
</div>

</div>
        </div>
    </section> */}


    <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Why Choose Us?</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at  to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


    {/* <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Shop by Popular Makes</h3>
                </div>
            </div>
            <div class="container">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">Acura</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Audi</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">BMW</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Buick</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Cadillac </div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Chevrolet</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Chrysler</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Dodge</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Ford</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">GMC</div>
    </div>
  </div>

  <div class="container mt-3">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">Honda</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Hyundai</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Infiniti</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Jaguar</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Jeep</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Kia</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Land Rover</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lexus</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lexus</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lincoln</div>
    </div>
    
  </div>
</div>
<div className="parts">
<a href="#" class="main-btnsss">See All</a>
</div>

</div>
        </div>
    </section> */}


    <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Happy Customers</h3>
                    <div class="team-imgs">
                            <img src={Review} class="img-fluid" />
                        </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        
                        <div class="team-info pt-4 text-center">
                        <p>The service was awesome! The part worked perfect and was delivered quickly. Great customer service!..</p>
                            <h5>Liam</h5>
                            
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        
                        <div class="team-info pt-4 text-center">
                        <p>Part delivered fits perfectly. Will shop here again as well as recommend family and friends.Budget friendly..</p>
                            <h5>Emma</h5>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        
                        <div class="team-info pt-4 text-center">
                        <p>The product fits perfectly and well priced. Will always buy parts from Skydrive autoparts. Perfect Score.</p>
                            <h5>Jordan</h5>
                            
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="appointment_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-8 col-10">
                    <h3>Contact Us</h3>
                    {/* <a href="tel:+16266999232"><h3></h3><span className='d-none'>tel:+16266999232</span></a> */}
                    <a href="tel:+16266999232" ><h3>+1 (626) 699-9232</h3><span className='d-none'>(626) 699-9232</span></a>
                    {/* <h3></h3> */}
                    <a href="tel:+16266999232" class="main-btn mt-4 fill-btn">Call Us !<span className='d-none'>(626) 699-9232</span></a>
                        <a href="#products" class="main-btn mt-4 ms-3">Email Us</a>
                        
                </div>
            </div>
        </div>
    </section>

    {/* <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Shop by Popular Brands</h3>
                </div>
            </div>
            <div class="container">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">A1 Cardone</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">AC Delco</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">DriveMotive</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">DriveWire</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Evan Fischer</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">JC Whitney</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Kool Vue</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Moog</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Ford</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">GMC</div>
    </div>
  </div>

  <div class="container mt-3">
  <div class="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
    <div class="col">
      <div class="p-3 border bg-light">Powerstop</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Hyundai</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Replacement</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">SureStop</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">TrueDrive</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Kia</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Land Rover</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lexus</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lexus</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Lincoln</div>
    </div>
    
  </div>
</div>
<div className="parts">
<a href="#" class="main-btnsss">See All</a>
</div>

</div>
        </div>
    </section>  */}

     
    {/* <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Our Dentists</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img src="./images/team/team1.png" class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Darry Milin</h5>
                            <p>Oral Surgeon</p>
                            <ul class="social-network">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img src="./images/team/team2.png" class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Salman Ahmed</h5>
                            <p>Oral Surgeon</p>
                            <ul class="social-network">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img src="./images/team/team3.png" class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Santa Binte</h5>
                            <p>Oral Surgeon</p>
                            <ul class="social-network">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section> */}

    
    {/* <section id="gallery" class="gallery_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3>Our Gallery</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <img src="./images/gallery/1.jpg" class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img src="./images/gallery/2.jpg" class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img src="./images/gallery/3.jpg" class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img src="./images/gallery/5.jpg" class="w-100 h-100" />
                </div>
                <div class="col-md-8 col-sm-6 mb-4">
                    <img src="./images/gallery/4.jpg" class="w-100 h-100" />
                </div>
            </div>
        </div>
    </section> */}

    
    {/* <section id="testimonial" class="testimonial_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Testimonials</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src="./images/testimonial/client1.png" class="img-fluid" />
                        <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p> 
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    <h4>Awesome Work</h4>
                    <p>“They really took my individual case to heart. Their team is very helpful. They all work
                        together in an impressive way to make sure that my needs were met and I walked out pain
                        free.”</p>
                </div>
            </div>
        </div>
    </section>  */}

    
    {/* <section id="blog" class="blog_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Latest Blog</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img src="./images/blog/1.jpg" />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Dental Insurance with Benefits</h5>
                            <h6 class="text-white">By Admin - February 18, 2018</h6>
                            <p class="mt-2 text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque,
                                nostrum.
                            </p>
                            <a href="#" class="main-btn mt-2">Read More</a>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img src="./images/blog/2.jpg" />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Dental Insurance with Benefits</h5>
                            <h6 class="text-white">By Admin - February 18, 2018</h6>
                            <p class="mt-2 text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque,
                                nostrum.
                            </p>
                            <a href="#" class="main-btn mt-2">Read More</a>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img src="./images/blog/3.jpg" />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Dental Insurance with Benefits</h5>
                            <h6 class="text-white">By Admin - February 18, 2018</h6>
                            <p class="mt-2 text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque,
                                nostrum.
                            </p>
                            <a href="#" class="main-btn mt-2">Read More</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}




    
    {/* <section id="contact" class="footer_wrapper wrapper">
        <div class="container pb-3">
            <div class="row">
                <div class="col-lg-3 col-md-6 mb-4">
                    <h5>About Skydrive Auto Parts Autoparts</h5>
                    <p class="ps-0">Skydrive Auto Parts Autoparts is a one-stop online shop for auto parts. We offer the most extensive selection of auto parts, import parts and automotive accessories, you can easily find the quality parts you need at the best prices.</p>
                    
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <h5>More Links</h5>
                    <ul class="link-widget p-0">
                        <li><a href="#">Popular Parts</a></li>
                        <li><a href="#">Warranty Policy</a></li>
                        <li><a href="#">Shipping Policy</a></li>
                        <li><a href="#">Popular Models</a></li>
                        <li><a href="#">Privacy & Policy</a></li>
                        <li><a href="#">Terms & Condition</a></li>
                        <li><a href="#">Return Policy</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6 mb-4">
                    <h5>More Links</h5>
                    <ul class="link-widget p-0">
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">All Products</a></li>
                        <li><a href="#">Contact Us</a></li>
                        <li><a href="#">Junk Yard Near Me</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 mb-4">
                    <h5>Newsletter</h5>
                    <div class="form-group mb-4">
                        <input type="email" class="form-control bg-transparent" placeholder="Enter Your Email Here" />
                        <button type="submit" class="main-btn rounded-2 mt-3 border-white text-white">Subscribe</button>
                        <div class="contact-info mt-4">
                        <ul class="list-unstyled p-0">
                            <li><a href="#"><i class="fa fa-home me-3"></i> United States of America</a></li>
                            <li><a href="#"><i class="fa fa-phone me-3"></i></a></li>
                            <li><a href="#"><i class="fa fa-envelope me-3"></i>info@example.com</a></li>
                        </ul>
                    </div>
                    </div>
                    <h5>Stay Connected</h5>
                    <ul class="social-network d-flex align-items-center p-0 ">
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram "></i></a></li>
                    </ul>
                </div>



            </div>
        </div>
        <div class="container-fluid copyright-section">
            <p class="p-0">Copyright <a href="#">© Skydrive Auto Partsautoparts.com</a> All Rights Reserved</p>
        </div>
    </section> */}
    <Footer />

    </>
  )
}

export default Home