import React from 'react'
import Hand from '../images/handshake.png'
import Return from '../images/return-box.png'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'
import Call from '../sections/Call'

const ReturnPolicy = () => {
  return (
    <div>
        <Call />
        <Navbar />
        <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-left mb-5">
                    <h3 class="text-black">Return Policy</h3>
                    <p>Return Policy for SkyDriveAutoParts.com</p>

<p>1. Return Period<br />
Customers have 30 days from the date of receipt to initiate a return.</p>

<p>2. Condition of Returned Items<br />
All returned items must be in their original, unused condition, with all original packaging, tags, and manuals included. Parts that have been installed or tampered with will not be accepted for return.</p>

<p>3. Return Authorization<br />
Before sending any returns, customers must first contact our customer service team at <a href="mailto:enquiry@skydriveautoparts.com">enquiry@skydriveautoparts.com</a> to obtain a Return Merchandise Authorization (RMA) number.</p>

<p>4. Shipping Costs<br />
Return shipping costs will be the responsibility of the customer unless the return is due to a mistake on our end, such as shipping the wrong item. In such cases, SkyDriveAutoParts.com will cover return shipping expenses.</p>

<p>5. Refunds<br />
Upon receipt and inspection of the returned product, a refund will be processed. Refunds are typically processed within 5-7 business days and will be credited to the original payment method. Original shipping fees, if applicable, are non-refundable.</p>

<p>6. Exchanges<br />
If you wish to exchange an item, please initiate a return for the original item and place a new order for the desired product.</p>

<p>7. Damaged or Incorrect Items<br />
If you receive a damaged or incorrect item, please contact our customer service team within 48 hours of delivery. We will provide instructions for returning the item and will send a replacement or issue a refund, as appropriate.</p>

<p>8. Non-Returnable Items<br />
Certain items, such as clearance or special-order parts, are non-returnable. Please review the product description carefully before making a purchase.</p>

<p>9. Restocking Fee<br />
Returns may be subject to a restocking fee of up to 15% at our discretion, especially for large items or high-value orders.</p>

<p>10. Return Address<br />
All returns should be sent to: <a href="mailto:enquiry@skydriveautoparts.com">enquiry@skydriveautoparts.com</a>. Please ensure the RMA number is clearly marked on the outside of the package.</p>

<p>11. Questions<br />
For any queries or concerns regarding our return policy, please reach out to our customer service team at <a href="mailto:enquiry@skydriveautoparts.com">enquiry@skydriveautoparts.com</a>.</p>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at +1(626) 699-9232 to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default ReturnPolicy