import React from 'react'
import Logo1 from '../images/bmw.png'
import Logo2 from '../images/ford.png'
import Logo3 from '../images/land-rover.png'

const Brands = () => {
  return (
    <>
    <section id="gallery" class="gallery_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3>Popular Brands</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4 logos">
                    <img src={Logo1} class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4 logos">
                    <img src={Logo2} class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4 logos">
                    <img src={Logo3} class="w-100 h-100" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Brands