import React from 'react'
import Procuct from '../images/product/product-module.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const CarACCompressor = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Car AC Compressor</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The AC compressor serves as one of the central part that creates the system’s necessary air cycle. Its primary role is to put the necessary amount of pressure on the car’s refrigerant in order to activate its heat-transfer characteristics and change temperatures. Thus giving you the cool comfortable air inside the car. Freon is the commonly used refrigerant which is a kind of a fluid that essentially makes cold air out of hot air. This refrigerant and the compressor are a vital duo that works in conjunction with each other. The most common reason why the AC compressor will fail are wear and tear due to age or neglect. In order to make sure you get a proper replacement for you’re a compressor we make sure that there a no leak and cracks.</p>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default CarACCompressor