import Review from "../images/google-reviews.png";
const Testimonial = () => {
  return (
    <section id="team" class="team_wrapper m-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center mb-5">
            <h3 class="text-black">Happy Customers</h3>
            <div class="team-imgs">
              <img alt="images" src={Review} class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-info pt-4 text-center">
                <p>
                  The service was awesome! The part worked perfect and was
                  delivered quickly. Great customer service!..
                </p>
                <h5>Mary</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-info pt-4 text-center">
                <p>
                  Part delivered fits perfectly. Will shop here again as well as
                  recommend family and friends.Budget friendly..
                </p>
                <h5>Luis</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-info pt-4 text-center">
                <p>
                  The product fits perfectly and well priced. Will always buy
                  parts from skydrive autoparts. Perfect Score.
                </p>
                <h5>Samuel</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Testimonial;
