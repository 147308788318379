import React from 'react'
import Hand from '../images/handshake.png'
import Return from '../images/return-box.png'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'
import Call from '../sections/Call'

const Warrenty = () => {
  return (
    <div>
        <Call />
          <Navbar />
        <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-left mb-5">
                    <h3 class="text-black">Warranty Policy</h3>
                    <p>30 DAY WARRANTY ON EXCLUSIVE AUTO PARTS</p>
<p>This warranty covers damaged parts as well as products that were delivered erroneously. A parts-only warranty is available. ANY PART SOLD BY EXCLUSIVE AUTO PARTS IS NOT COVERED FOR LABOR COSTS.</p>
<p>If the part is defective, we will first try to replace it; if this is not possible, we will give a refund for the entire purchase price.</p>
<p>If a reorder is placed before the first part is returned, we will charge for the second part and reimburse the first order when the first part is returned.</p>
<p>The cost of return postage will not be repaid.</p>
<p>Please offer a comprehensive explanation as to why the part is being returned when contacting Exclusive Auto Parts.</p>
<p>If a part is returned that does not meet the warranty’s specifications, Exclusive Auto Parts maintains the right to charge a 25% restocking fee and deduct shipping costs from the refund.</p>
<p>Exclusive Auto Parts is not responsible for items delivered to the address provided at the time of sale and not taken into possession by the client, as evidenced by tracking information.</p>
<p>Items ordered for ‘testing purposes only’ or improperly ordered by the customer are not returnable.</p>
<p>Before ordering a part, check with a mechanic or an auto part expert to make sure it’s the right one. We do a fantastic job of shipping accurate parts, but we would ask that you, our customer, do your homework before purchase.</p>
<p>Items that are returned after the warranty period have expired will not be refunded and will be returned to the sender.</p>
<p>To be accepted, parts must be returned within 30 days of the original shipping delivery date.</p>
<p>The exceptions listed below will either nullify a warranty or are exceptions to our usual 30-day warranty...</p>

<p>Return Policy</p>
<p>Items that have been modified or disassembled for testing purposes are not returnable.</p>
<p>Items that are not the same as the ones that were shipped will not be reimbursed. PARTS ARE IDENTIFIED UNIQUELY BY MARKINGS ON THEM.</p>
<p>Items returned that are not in the same condition as when they were shipped (with the exception of items that were damaged in transit and were verified by an Exclusive Auto Parts representative) will not be refunded.</p>
<p>To verify an issue with a specific item or installation, ALL PROBLEMS MUST BE IN WRITING through MAIL, EMAIL.</p>
<p>Please contact our customer service department if you receive a broken or incorrect part. DO NOT RETURN THE PART UNTIL YOU HAVE RECEIVED AN RMA.</p>
<p>The customer is responsible for returning the part, and Exclusive Auto Parts must be given a return tracking number when the item is returned.</p>
<p>Within 7 business days after receiving the RMA, the item must be returned.</p>
<p>When we are contacted, we will send the return address.</p>
<p>After receiving an RMA, parts must be returned to the location address specified. Items returned to Exclusive Auto Parts will be forwarded at the customer’s cost to the correct address stated on the RMA instructions.</p>
<p>We would gladly give a full refund on the original purchase price or send a new part once the item is returned.</p>
<p>When the item is returned, it will be inspected and a credit request will be sent to accounting if it is approved. Once the returned part has been verified and examined, the customer will receive a refund.</p>
<p>We stand by our guarantees and want to put your mind at ease when buying a used auto component from us.</p>
<p>Please contact us on +1(626) 699-9232 if you have any questions.</p>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at +1(626) 699-9232 to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default Warrenty