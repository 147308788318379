import {
  Box,
  Button,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../images/logo.jpg";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import '../App.css'
const Footer = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(34,34,34)",
          padding: "20px 30px 40px 30px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box component="img" src={logo} sx={{ maxWidth: "200px" }} />
            <Typography variant="body2" color="#fff">
            Skydrive Auto parts is a one-stop online shop for auto parts. We
              offer the most extensive selection of auto parts, import parts and
              automotive accessories, you can easily find the quality parts you
              need at the best prices.
            </Typography>
            <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <HomeRoundedIcon
                  sx={{ color: "rgb(192,0,0)", fontSize: "20px" }}
                />
                <Typography variant="body2" color="#fff">
                440 N BARRANCA AVE #6158 COVINA, CA 91723
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CallRoundedIcon
                  sx={{ color: "rgb(192,0,0)", fontSize: "20px" }}
                />
                <Typography variant="body2" color="#fff">
                +1(626) 699-9232
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <EmailRoundedIcon
                  sx={{ color: "rgb(192,0,0)", fontSize: "20px" }}
                />
                <Typography variant="body2" color="#fff">
                enquiry@skydriveautoparts.com
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Box sx={{ paddingTop: 3 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "40px",
                  borderBottom: "3px solid rgb(192,0,0)",
                  color: "#fff",
                  marginLeft: 1,
                }}
              >
                Company
              </Typography>
              <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/about-us"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    About Us
                  </Link>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/contact"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Contact Us
                  </Link>
                </Stack>
                {/* <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="#"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Popular Parts
                  </Link>
                </Stack> */}
                {/* <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="#"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Popular Models
                  </Link>
                </Stack> */}
                {/* <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="#"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Contact Us
                  </Link>
                </Stack> */}
                {/* <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="#"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Junk Yard Near Me
                  </Link>
                </Stack> */}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Box sx={{ paddingTop: 3 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "40px",
                  borderBottom: "3px solid rgb(192,0,0)",
                  color: "#fff",
                  marginLeft: 1,
                }}
              >
                Important Links
              </Typography>
              <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/warrenty"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Warranty Policy
                  </Link>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/shipping"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Shipping Policy
                  </Link>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/privacy"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Privacy & Policy
                  </Link>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/returnpolicy"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Return Policy
                  </Link>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <ArrowRightRoundedIcon sx={{ color: "rgb(192,0,0)" }} />
                  <Link
                    href="/terms"
                    underline="hover"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(192,0,0)",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Terms & Condition
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ paddingTop: 3 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "80px",
                  borderBottom: "3px solid rgb(192,0,0)",
                  color: "#fff",
                }}
              >
                Newsletter
              </Typography>
              <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 3 }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Enter your e-mail id here"
                  sx={{
                    background: "#fff",
                    borderRadius: 1,
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "rgb(192,0,0)",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "capitalize" }}
                >
                  Subscribe
                </Button>
              </Stack>
              <Typography
                variant="subtitle1"
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  width: "100px",
                  borderBottom: "3px solid rgb(192,0,0)",
                  color: "#fff",
                }}
              >
                Follow Us On
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="center"
              >
                <a href="https://www.facebook.com/people/Skydrive-Autoparts/61550478004917/" target="_blank" rel="noopener noreferrer">
    <FacebookIcon
        sx={{
            color: "#fff",
            "&:hover": {
                color: "rgb(192,0,0)",
                textDecoration: "none",
            },
        }}
    />
</a>
<a href="https://www.instagram.com/skydriveautoparts/" target="_blank" rel="noopener noreferrer">
    <InstagramIcon
        sx={{
            color: "#fff",
            "&:hover": {
                color: "rgb(192,0,0)",
                textDecoration: "none",
            },
        }}
    />
</a>
                {/* <TwitterIcon
                  sx={{
                    color: "#fff",
                    "&:hover": {
                      color: "rgb(192,0,0)",
                      textDecoration: "none",
                    },
                  }}
                />
                <GoogleIcon
                  sx={{
                    color: "#fff",
                    "&:hover": {
                      color: "rgb(192,0,0)",
                      textDecoration: "none",
                    },
                  }}
                /> */}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#000", padding: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Typography
            variant="subtitle1"
            sx={{ textTransform: "capitalize", color: "#fff" }}
          >
            Copyrights © 2023 All Rights Reserved, Powered by
          </Typography>
          <Box component="img" src={logo} sx={{ maxWidth: "100px" }} />
        </Stack>
      </Box>
    </>
  );
};
export default Footer;
