import Hand from "../images/handshake.png";
import Return from "../images/return-box.png";
const WhyChooseUs = () => {
  return (
    <section id="team" class="team_wrapper m-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center mb-5">
            <h3 class="text-black">Why Choose Us?</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Hand} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>Buy With Confidence</h5>
                <p>
                  Delivering reliable auto parts service since 2008 &nbsp;
                  &nbsp; &nbsp;
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Return} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>30 Days Returns</h5>
                <p>
                  Ordered the wrong part? Enjoy peace of mind with easy returns
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Return} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>Call To Order</h5>
                <p>
                  Need help ordering? Call us toll free at +1 (626) 699-9232 to
                  order
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Hand} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>Fast Delivery</h5>
                <p>Get the parts and accessories you need when you need them</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Hand} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>Huge Selection</h5>
                <p>
                  Huge selection of OEM, stock replacement or aftermarket
                  accessories
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-4">
            <div class="card rounded-3">
              <div class="team-img">
                <img alt="images" src={Hand} class="img-fluid" />
              </div>
              <div class="team-info pt-4 text-center">
                <h5>Competitive Prices</h5>
                <p>
                  We have the all the parts you need at the most competitive
                  prices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhyChooseUs;
