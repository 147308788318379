  import React, { useState } from 'react';
  import logo from '../images/logo.jpg';
  import'./Navbar.css'
  import Marquee from './Marquee';
  import Phone from './Phone';

  function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
      setIsOpen(!isOpen);
    };

    return (
      <>
      <Marquee />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="d-flex w-100 justify-content-between align-items-center">
          {/* Wrap logo inside an anchor tag */}
          <a href="/">
              <img src={logo} alt="Logo" className="navbar-logo" style={{ maxWidth: "140px" }} />
            </a>

          <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
      <span className="navbar-toggler-icon"></span>
  </button>

          </div>

          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
              <li className="nav-item"><a className="nav-link" href="#search">Search Parts</a></li>
              <li className="nav-item"><a className="nav-link" href="/about-us">About Us</a></li>
              <li className="nav-item"><a className="nav-link" href="/contact">Contact Us</a></li>
            </ul>
            <a className="nav-link special-contact" href="tel:+16266999232">24X7 Auto Parts Assistant +1 (626) 699-9232</a>
          </div>
        </div>
      </nav>
      <Phone />
      </>
      
    );
  }

  export default Navbar;
