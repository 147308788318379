import React from 'react'
import Tele from "../images/telephone.png";

const Call = () => {
  return (
   <>
   <div class="call-icon-container circle circle1 circle2 circle3">
        <a href="tel:+16266999232" class="ripple-effect">
            <img src={Tele} alt="Call Icon" /><span className='d-none'>tel:+16266999232</span>
        </a>
    </div>
   </>
  )
}

export default Call