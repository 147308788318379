import React from 'react'
import Procuct from '../images/product/rear-axle.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const Abs = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">ABS</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>Anti-lock Braking System helps you steer in emergencies by restoring traction to your tires. It helps prevents wheels from locking up – possibly allowing the driver to steer to safety. The anti-lock brakes help you steer as you brake during an emergency.</p>
                    <p>There are four main components to an ABS system:
                        <li>Speed Sensors</li>
                        <li>Pump</li>
                        <li>Valves</li>
                        <li>Controller</li>
                    </p>
                    <p><b>Speed Sensors –</b> the anti-lock braking system needs some way of knowing when a wheel is about to lock up. The speed sensors, which are located at each wheel, or in some cases in the differential,provide this information.</p>
                    <p><b>Pump</b> Since the valve is able to release pressure from the brakes, there has to be some way to put that pressure back. That is what the pump does; when a valve reduces the pressure in a line, the pump is there to get the pressure back up.</p>
                    <p><b>Valves</b> There is a valve in the brake line of each brake controlled by the ABS.</p>
                    <p><b>Controller</b> The controller is a computer in the car. It watches the speed sensors and controls the valves.</p>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default Abs