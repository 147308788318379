
import React from 'react'
import Procuct from './images/product/used-engine-image-det.png'
import AutoPartsSearch from './sections/AutoPartsSearchBar'
import Navbar from "./sections/Navbar";
import Testimonial from './sections/Testimonial'
import WhyChooseUs from './sections/WhyChooseUs'
import Footer from './sections/Footer'
import Brands from './sections/Brands'
import Assistant from './sections/Assistant'
import Check from './images/check-mark.png'

const Thankyou = () => {
  return (
    <>
    <Navbar />
    
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                <img src={Check} class="img-fluid check" />
                    <h3 class="text-black">Thankyou</h3>
                    <a href="tel:+16266999232" class="main-btn mt-4 fill-btn"><span>&#128222;</span> Call To Order !<span className='d-none'>(626) 699-9232</span></a>
                </div>
            </div>
            <div class="row align-items-center">
                {/* <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        
                    </div>
                </div> */}
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p className='text-success'><b>We 100% GUARANTEE to locate your auto part within 1 - 3 hours or less.</b></p>
                    <p><b>Easy exchange policy for the products we offer.</b></p>
                    <li>Widest Assortment of car parts to choose from.</li>
                    <li>Customer’s satisfaction is our foremost priority.</li>
                    <li>Full warranty on the parts we sell.</li>
                    <li>Strict quality testing of the components to ensure excellent performance and durability.</li>
                    <li>Friendly customer service rendered in a hassle-free manner.</li>
                    <li>Super-durable products at the most competitive rates.</li>
                    <li>All your queries are valued and resolved related to the product.</li>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default Thankyou