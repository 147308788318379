import React from 'react'
import Procuct from '../images/product/categories-lighting.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const Lighting = () => {
  return (
    <>
    <Navbar />
   <div id="products" class="wrapper pb-0 mb-5">
         <div class="container">
           <div class="row">
             <div class="col-sm-12 text-center mb-5">
               <h3 class="text-black">Find My Part Now</h3>
             </div>
             <Search />
           </div>
         </div>
       </div>
   <section id="testimonial" class="testimonial_wrapper wrapper">
   
       <div class="container">
           <div class="row">
               <div class="col-sm-12 text-center mb-4">
                   <h3 class="text-black">Lighting</h3>
               </div>
           </div>
           <div class="row align-items-center">
               <div class="col-xl-3 col-md-4 col-sm-6">
                   <div class="card text-center">
                       <img src={Procuct} class="img-fluid" />
                       {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                       <p>CEO of Prime IT</p>  */}
                   </div>
               </div>
               <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                   {/* <h4>Awesome Work</h4> */}
                   <p>The lighting system of a motor vehicle consists of lighting and signaling devices mounted on the front, rear, sides and in some cases on the top of a motor vehicle and lights the roadway for the driver. The numerous lights on an automobile have distinct purpose such as assisting the driver or signaling to other cars.</p>
                   <p>Headlights are one of the essential features of an automobile when travelling down a dark and gloomy road. There are usually two or more beams in these. For driving, one beam offers the most illumination. The other beam is constructed to deflect to the ground and the side of the road. This beam becomes necessary to reduce glare when passing other cars on the road.</p>
                   <p>Low beam headlights provide enough light to see where you are going without creating excessive glare that might blind oncoming ehicles. Low beams are required at night, however they have also been shown to increase visibility and safety when used during the day. Low beams provide enough light to give forward and lateral illumination without blinding other road uaser.</p>
                   <p>Full beam headlights, like low beam lights aid the driver in seeing the road in the dark. It also lets other drivers know where you are. Full-beam headlights produce a powerful, center-weighted light distribution with several glares.</p>
                   <p>Taillights reveal the back of a car, including the license plate, so that other drivers can see both the vehicle and the license plate. This allows cars behind you to understand that you are there and how far ahead you are.</p>
                   <p>Blinker lights/signal lights/indicator lights are situated in the front and back of the automobile, beside the head and taillights and are sometimes known as turn signals or “blinkers”.</p>
               </div>
           </div>
       </div>
   </section> 
   <Assistant />
   <Brands />
   <Testimonial />
   <WhyChooseUs />
   <Footer />
   </>
  )
}

export default Lighting