import React from 'react'
import Procuct from '../images/product/used-engine-image-det.png'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const EngineAccessories = () => {
  return (
    <>
     <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Engine Accessories</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The engine comes along with its accessories, without which the entity of engine cannot work. The job of this to transfer the power generated by the engine to the wheel. They are also responsible for how much power each tyre must get. The major parts of the engine include the lower cylinder block, upper cylinder head, and the cooling and lubricating systems. With proper care and maintenance the engine will last many years before any replacement is necessary. We here at Welyard make sure our inventory of engine accessories is maintained everyday, checked, cleaned if need and serviced in the right manner so that you get absolutely worry free replacement</p>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default EngineAccessories