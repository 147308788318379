import React from 'react'
import Procuct from '../images/product/used-engine-image-det.png'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const Engines = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Used Engines</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The engine is the heart of the car. It is a very complex structure which converts heat from burning fuel into the force that turns the road wheels. The major parts of the engine include the lower cylinder block, upper cylinder head and the cooling and lubricating systems. It is very important to maintain these major parts for long lasting working of the engine. We understand the worry you go through about engine break down; we offer a huge selection of exceptional quality engine components from gaskets, timing belt kits, oil pans and engine mounts.</p>
                    <p>We check each and every part carefully before handing out your auto part. We have curated a huge selection of used car engines and we make sure to double check for any leaks or cracks before delivering the parts to you. Be assured that you will never get any faulty part from us.</p>

                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default Engines