import React from 'react';
import Footer from "../sections/Footer";
import Testimonial from "../sections/Testimonial";
import WhyChooseUs from "../sections/WhyChooseUs";
import Navbar from "../sections/Navbar";
import Call from "../sections/Call";
import Search from "../Search";
import Forms from "../sections/Forms";
import './Contact.css';

const Contact = () => {
  return (
    <>
      <Call />
      <Navbar />

      <div className="contact-containerss mt-5">
        <div className="contact-contentss">
          <iframe 
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=..."
            allowFullScreen 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
          />

          <div className="contact-detailsss">
            <h2>Contact Details</h2>
            <h4 className='text-white'>Find Location</h4>
            
            <div className="contact-itemss">
              <h5 className='text-white'>Registered Address</h5>
              <div className="contact-item-inner">
                <i className="bi-house-door-fill"></i>
                <p>440 N BARRANCA AVE #6158 COVINA, CA 91723</p>
              </div>
            </div>
            <div className="contact-item">
              <h5 className='text-white'>Call Us 24/7</h5>
              <div className="contact-item-inner">
                <i className="bi-telephone-fill"></i>
                <p className='text-white'>+1(626) 699-9232</p>
              </div>
            </div>
            <div className="contact-item">
              <h5 className='text-white'> Mail Us</h5>
              <div className="contact-item-inner">
                <i className="bi-envelope-fill"></i>
                <p className='text-white'>enquiry@skydriveautoparts.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-forms">
          <h3>Quick Contact</h3>
          <p>Fill in the details to get in touch with us</p>
          <Forms />
        </div>

        <div className="contact-searchs">
          <h3>Find My Part Now</h3>
          <Search />
        </div>
      </div>

      <Testimonial />
      <WhyChooseUs />
      <Footer />
    </>
  )
}

export default Contact;
