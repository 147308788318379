import React from 'react'

const Marquee = () => {
  return (
    <>
    <marquee direction="left" speed="normal" behavior="loop" class="mrquuee">Free Shipping* | 30 Days Return &amp; Refund Warranty*</marquee>
    </>
  )
}

export default Marquee