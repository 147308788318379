import React from 'react'
import Procuct from '../images/product/product-cylinder-head.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const CylinderHead = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Cylinder Head</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The main task of the cylinder head is to close the combustion chamber of the engine from the top. The upper part of the engine is called the cylinder head, while the lower part is the engine block. The cylinder head sits on the engine and closes off the combustion chamber. The cylinder head is usually made of gray iron or aluminum alloy. The passages in the cylinder head allows air and fuel to flow inside the cylinder while permitting the exhaust gases to flow out. The cylinder head also channels the coolant into the engine block, thereby cooling down the engine component. The cylinder head uses a gasket that helps in preventing water or oil from leaking into the combustion chamber. The most common problem with cylinder head is it cracks due to engine overheating. A cracked cylinder head may leak the coolant out due to which the engine will fail to cool thus resulting I further engine failure. It is necessary to check the cylinder head from time to time. We make sure that the cylinder head are not cracked and we deliver parts which are as good as new.</p>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default CylinderHead