import React from 'react'

const Assistant = () => {
  return (
    <>
    <section class="appointment_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-8 col-10">
                    <h3>Free Assistance: Contact Us</h3>
                    <h3>+1 (626) 699-9232</h3>
                    <a href="#" class="main-btn mt-4 fill-btn">Call Us !</a>
                        <a href="#products" class="main-btn mt-4 ms-3">Email Us</a>
                    
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Assistant