import React from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
} from "@mui/material";

emailjs.init("SfOClGOmr-BY7jgbI");

const Forms = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState(""); 
    const [query, setQuery] = React.useState("");

    emailjs.init("YOUR_USER_ID");

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_lwpcww4', 'template_k6gip9c', {
            first_name: firstName,
            last_name: lastName,
            user_phone: phone,
            user_email: email,
            user_query: query
        })
        .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            navigate("/thankyou");  // Redirect to the thank you page
        })
        .catch((error) => {
            console.log('Email failed to send.', error);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        label="First Name" 
                        variant="outlined" 
                        fullWidth 
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        label="Last Name" 
                        variant="outlined" 
                        fullWidth 
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        label="Phone" 
                        variant="outlined" 
                        fullWidth 
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        label="Email address" 
                        variant="outlined" 
                        fullWidth 
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        label="Your Query" 
                        variant="outlined" 
                        fullWidth 
                        multiline 
                        rows={4} 
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default Forms;
