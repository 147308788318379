import { Box, Container, Stack, Typography } from "@mui/material";
import Footer from "../sections/Footer";
import Testimonial from "../sections/Testimonial";
import WhyChooseUs from "../sections/WhyChooseUs";
import image from "../images/about-us.jpg";
import Logo from "../images/logo2.png";
import Navbar from "../sections/Navbar";
import Call from "../sections/Call";
import "../App.css"

const AboutUs = () => {
  return (
    <>
      
      <Call />
      <Navbar />
      <Container style={{ marginTop: '30px' }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2} q
          alignItems="center"
        >
          <Box component="img" src={image} />
          <Stack direction="column" spacing={2}>
            <Typography variant="body1">
              Skydrive Auto Parts is your all in one resource for substitution
              great utilized OEM auto parts and adornments. We have served
              countless clients across the world, working connected at the hip
              to discover great parts for your vehicle.
            </Typography>
            <Typography variant="body1">
              Our goal is to find you the correct part at an inexpensive price,
              close to your home or business. We can help you locate those
              hard-to-find parts. We ship your order directly to your home,
              business or to your mechanic to help you get back onto the road as
              rapidly as possible.
            </Typography>
            <Typography variant="body1">
              We understand that your car or truck can break-down anytime, and
              that there is no holiday for your disabled vehicle. We staff sales
              professionals 365 days a year. Our sales department is available
              to help you to place new orders from 6am to 9pm CST, 7 days a
              week.
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body1">Give us a call today at </Typography>
              <Typography variant="body1" color="red" fontWeight="bold">
              +1 (626) 699-9232
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Testimonial />
      <WhyChooseUs />
      <Footer />
    </>
  );
};
export default AboutUs;
