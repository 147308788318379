import "./App.css";

import Home from "./Home";
import Engines from "./pages/Engines";
import Transmissions from "./pages/Transmissions";
import TransferCase from "./pages/TransferCase";
import { BrowserRouter as Main, Route, Routes } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Warrenty from "./pages/Warrenty";
import Shipping from "./pages/Shipping";
import Contact from "./pages/Contact";
import Return from "./pages/ReturnPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import Abs from "./pages/Abs";
import RearAxle from "./pages/RearAxle";
import DriveShaft from "./pages/DriveShaft";
import TemperatureControlUnit from "./pages/TemperatureControlUnit";
import Thankyou from "./Thankyou";
import CylinderHead from "./pages/CylinderHead";
import SteeringColumn from "./pages/SteeringColumn";
import CarACCompressor from "./pages/CarACCompressor";
import ExhaustManifold from "./pages/ExhaustManifold";
import RackPinion from "./pages/RackPinion";
import EngineAccessories from "./pages/EngineAccessories";
import Wheels from "./pages/Wheels";
import SuspensionParts from "./pages/SuspensionParts";
import BrakeParts from "./pages/BrakeParts";
import SteeringAccessories from "./pages/SteeringAccessories";
import Lighting from "./pages/Lighting";
import BrakesSuspensionSteering from "./pages/BrakesSuspensionSteering";

function App() {
  return (
    <Main>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/engines" element={<Engines />}></Route>
        <Route path="/transmissions" element={<Transmissions />}></Route>
        <Route path="/transfercase" element={<TransferCase />}></Route>
        <Route path="/rearaxle" element={<RearAxle />}></Route>
        <Route path="/abs" element={<Abs />}></Route>
        <Route path="/driveshaft" element={<DriveShaft />}></Route>
        <Route path="/temperaturecontrolunit" element={<TemperatureControlUnit />}></Route>
        <Route path="/cylinderhead" element={<CylinderHead />}></Route>
        <Route path="/steeringcolumn" element={<SteeringColumn />}></Route>
        <Route path="/caraccompressor" element={<CarACCompressor />}></Route>
        <Route path="/exhaustmanifold" element={<ExhaustManifold />}></Route>
        <Route path="/rackpinion" element={<RackPinion />}></Route>
        <Route path="/engineaccessories" element={<EngineAccessories />}></Route>
        <Route path="/wheels" element={<Wheels />}></Route>
        <Route path="/suspensionparts" element={<SuspensionParts />}></Route>
        <Route path="/brakeparts" element={<BrakeParts />}></Route>
        <Route path="/steeringacce" element={<SteeringAccessories />}></Route>
        <Route path="/lighting" element={<Lighting />}></Route>
        <Route path="/BrakesSuspensionSteering" element={<BrakesSuspensionSteering />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/warrenty" element={<Warrenty />}></Route>
        <Route path="/shipping" element={<Shipping />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/returnpolicy" element={<ReturnPolicy />}></Route>
        <Route path="/thankyou" element={<Thankyou />}></Route>
      </Routes>
    </Main>
  );
}

export default App;
