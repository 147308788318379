import React from 'react'
import Procuct from '../images/product/exhaust-manifold.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const ExhaustManifold = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Exhaust Manifoldr</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The exhaust manifold is tasked with collecting the exhaust fumes generated by an internal combustion engine and conveying them towards the catalytic converter or turbocharger. It is bolted directly to the engine block, the exhaust manifold is the first section of a vehicle’s exhaust system. Exhaust manifolds are often made of alloyed cast iron, which is able to withstand the high exhaust temperatures. Alternatively, exhaust manifolds made of stainless steel are also used.</p>
                    <p>To ensure low pollutant emissions, it is important for the catalytic converter to reach its operating temperature quickly. The thermal capacity of the exhaust manifold plays an important part in this. Exhaust manifolds should be installed according to the vehicle manufacturer’s specifications. Likewise, when replacing the manifold, new gaskets must be used to ensure that exhaust fumes do not leak from the components.</p>
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default ExhaustManifold