import React from 'react'
import Procuct from '../images/product/product-abs.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const BrakeParts = () => {
  return (
    <>
    <Navbar />
   <div id="products" class="wrapper pb-0 mb-5">
         <div class="container">
           <div class="row">
             <div class="col-sm-12 text-center mb-5">
               <h3 class="text-black">Find My Part Now</h3>
             </div>
             <Search />
           </div>
         </div>
       </div>
   <section id="testimonial" class="testimonial_wrapper wrapper">
   
       <div class="container">
           <div class="row">
               <div class="col-sm-12 text-center mb-4">
                   <h3 class="text-black">Brake Parts</h3>
               </div>
           </div>
           <div class="row align-items-center">
               <div class="col-xl-3 col-md-4 col-sm-6">
                   <div class="card text-center">
                       <img src={Procuct} class="img-fluid" />
                       {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                       <p>CEO of Prime IT</p>  */}
                   </div>
               </div>
               <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                   {/* <h4>Awesome Work</h4> */}
                   <p>One of the most important system on the vehicle is the brakes which safely brings the vehicle to a stop every time you hit the brake pedal. When you step on the brake pedal, the speed of the spinning wheels underneath the vehicle is reduced proportionately to how much pressure is applied on the pedal. A vehicle will either have a disc braking system or drum braking system to create the necessary friction for this to take place. Another brake system called the power brake system use a brake booster the amplifies the force applied to the brake pedal. Power braking allows the driver to just apply a little pressure to the brake pedal in order for the vehicle to slow down.</p>
                   <p>The components that make up the car brake system are –</p>
                   <li>Master Cylinder</li>
                   <li>Brake Rotor (Disc Brakes)</li>
                   <li>Brake Drum (Drum Brakes)</li>
                   <li>Brake Pad (Disc Brakes)</li>
                   <li>Brake Caliper (Disc Brakes)</li>
                   <li>Brake Shoe (Drum Brakes)</li>
                   <li>Brake Booster</li>
                   <li>Brake Pedal</li>
                   <li>Wheel Speed Sensors (ABS)</li>
                   <li>ABS Module</li>
                   <li>Brake Lines</li>
               </div>
           </div>
       </div>
   </section> 
   <Assistant />
   <Brands />
   <Testimonial />
   <WhyChooseUs />
   <Footer />
   </>
  )
}

export default BrakeParts