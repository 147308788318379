
import Hand from '../images/handshake.png'
import Return from '../images/return-box.png'
import Call from '../sections/Call'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'



const Terms = () => {
  return (
    <div>
        <Call />
        <Navbar />
        <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-left mb-5">
                    <h3 class="text-black">Terms & Condition</h3>
                    <p>
                    Terms & Conditions for SkyDriveAutoParts.com</p>

<p> 1. Introduction
This document sets forth the Terms and Conditions ("Terms") governing the use of SkyDriveAutoParts.com ("we", "us", "our") and the purchase of auto parts.
</p>
<p> 2. Use of the Website
By accessing or using our website, you agree to adhere to these Terms. If you disagree with any part of the Terms, refrain from using our website.
</p>
<p> 3. Product Descriptions
We make every effort to ensure the accuracy of product descriptions. However, we cannot guarantee that all information is accurate, complete, or error-free. Any discrepancies can be reported to customer service.
</p>
<p> 4. Pricing
Prices for products are subject to change without notice. All prices are in U.S. dollars and do not include applicable taxes or shipping fees.
</p>
<p> 5. Payment
Payments are accepted through recognized online payment gateways. Orders will not be dispatched until payment is received in full.
</p>
<p> 6. Shipping and Delivery
While we aim for timely deliveries, all shipping times are estimates and cannot be guaranteed. Any delays or damages during shipping are the responsibility of the shipping company.
</p>
<p> 7. Returns and Refunds
Returned products must be in original condition. Requests for returns must be made within 14 days of receiving the product. Refunds will be processed upon product inspection.
</p>
<p> 8. Limitation of Liability
SkyDriveAutoParts.com shall not be liable for any consequential damages resulting from the use of our products.
</p>
<p> 9. Intellectual Property
The content, logo, and other visual media are our property and are protected by copyright laws.
</p>
<p> 10. Amendments
We reserve the right to update or change these Terms at any time, and it is the customer’s responsibility to check this page periodically for changes.
</p>
<p> 11. Governing Law
These Terms & Conditions and any separate agreements are governed by and construed in accordance with the laws of the United States.
</p>
<p> 12. Dispute Resolution
Any disputes arising from these Terms will first be resolved through mediation. If mediation is unsuccessful, the dispute will be submitted to a competent court in the USA.
</p>
<p> 13. Contact Information
For any queries regarding these Terms, please contact us at enquiry@skydriveautoparts.com.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at +1(626) 699-9232 to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default Terms