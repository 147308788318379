import React from 'react'
import Procuct from '../images/gallery/top-category-brakes-suspension-and-steering.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const BrakesSuspensionSteering = () => {
  return (
    <>
    <Navbar />
   <div id="products" class="wrapper pb-0 mb-5">
         <div class="container">
           <div class="row">
             <div class="col-sm-12 text-center mb-5">
               <h3 class="text-black">Find My Part Now</h3>
             </div>
             <Search />
           </div>
         </div>
       </div>
   <section id="testimonial" class="testimonial_wrapper wrapper">
   
       <div class="container">
           <div class="row">
               <div class="col-sm-12 text-center mb-4">
                   <h3 class="text-black">Brakes, Suspension & Steering</h3>
               </div>
           </div>
           <div class="row align-items-center">
               <div class="col-xl-3 col-md-4 col-sm-6">
                   <div class="card text-center">
                       <img src={Procuct} class="img-fluid" />
                       {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                       <p>CEO of Prime IT</p>  */}
                   </div>
               </div>
               <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                   {/* <h4>Awesome Work</h4> */}
                   <p>Your vehicle's brake system is the silent hero, ensuring safety by slowing or halting wheel rotation via friction. This is achieved by converting the pressure you apply to the brake pedal into force on the brake pads or shoes, all managed by a hydraulic system. Similarly, the suspension system bears the weight of your vehicle, guarantees tire-road contact, directs movement, ensures wheel alignment, and cushions against the jolts of uneven roads.</p>
                   <p>Modern power-assisted steering systems pivot on two designs – the robust parallelogram, commonly seen in pickups, SUVs, and larger vehicles; and the lightweight, efficient rack & pinion steering, now a favorite for its fewer components.</p>
                   <p>At Skydrive Auto Parts, we stress the importance of regular inspections. Worn-out brake pads, shock absorbers, or tie rods can jeopardize your safety. Our range offers top-quality brake, suspension, and steering parts at unbeatable prices.</p>
                   <p>Ensuring your car brakes aptly – and doesn't 'break' (see what we did there?) – is a cornerstone of safety. Dive into our extensive collection of brake components. Discover a fault? Visit your local service technician, determine the issue, and return to us for cost-effective solutions.</p>
                   <p>Your vehicle's suspension and steering systems are the unsung heroes, ensuring you maintain a smooth and controlled course. If you ever find your car drifting off its path, it's likely time to inspect these systems. And for all your suspension and steering replacement needs, trust Skydrive Auto Parts. We're here to keep you on the road, confidently and safely.</p>
                   
               </div>
           </div>
       </div>
   </section> 
   <Assistant />
   <Brands />
   <Testimonial />
   <WhyChooseUs />
   <Footer />
   </>
  )
}

export default BrakesSuspensionSteering