import React from 'react'
import'./Navbar.css'

const Phone = () => {
  return (
    <div className="phone-link-container">
      <a className="nav-link special-contact" href="tel:+16266999232">Call Us +1 (626) 699-9232</a>
    </div>
  )
}

export default Phone