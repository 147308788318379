import React from 'react'
import Procuct from '../images/product/product-temperature-control-unit.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const TemperatureControlUnit = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Temperature Control Unit</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>The temperature control unit referes to the maintenance of passenger compartment temperature and humidity at a present level, regardless of the outside weather conditions. Therefore, the temperature controls also holds the relative humidity in the compartment at a suitable level and prevents window fogging. Many temperature control systems incorporate self-diagnostic test provisions where an on-board microprocessor controlled subsystem displays a code. This code indicates the cause of malfunction. Temperature control unit has a control panel inside the instrument panel to give access to both the driver and in-passenger to operate the air-conditioning and heating system. Other components of the temperature control unit are – programmer, power module, Clutch diode, High-side temperature switch, low-side temperature switch, high-pressure switch, low-pressure switch, pressure cycling switch, sun-load sensor, Outside temperature sensor, in-car temperature sensor, aspirator, coolant temperature sensor, Vehicle speed sensor, throttle position sensor, heat turn-on switch, brake booster vacuum switch, power steering cut-off switch, mode actuators, water valve actuators.</p>
                    
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default TemperatureControlUnit