import React from 'react'
import Procuct from '../images/product/product-rack-pinion.jpg'
import AutoPartsSearch from '../sections/AutoPartsSearchBar'
import Navbar from "../sections/Navbar";
import Testimonial from '../sections/Testimonial'
import WhyChooseUs from '../sections/WhyChooseUs'
import Footer from '../sections/Footer'
import Brands from '../sections/Brands'
import Assistant from '../sections/Assistant'
import Search from '../Search';

const RackPinion = () => {
  return (
    <>
    <Navbar />
    <div id="products" class="wrapper pb-0 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center mb-5">
                <h3 class="text-black">Find My Part Now</h3>
              </div>
              <Search />
            </div>
          </div>
        </div>
    <section id="testimonial" class="testimonial_wrapper wrapper">
    
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Rack and Pinion</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img src={Procuct} class="img-fluid" />
                        {/* <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>  */}
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    {/* <h4>Awesome Work</h4> */}
                    <p>A rack and pinion steering system consists of a pinion (a circular gear) with a rack ( a linear gear). The system works by converting a revolving motion into linear motion. With the rack and pinion steering, the rotation of the pinion causes linear motion of the rack, which turns the vehicle left or right. The rack and pinion gearset does two things – it converts the rotational motion of the steering wheel into the linear motion needed to turn the wheels.</p>
                    <p>It provides a gear reduction, making it easier to turn the wheels. Generally lighter, sportier cars have lower steering ratios than larger cars. The lower ratio gives the steering a quicker response – thus you don’t have to turn the steering wheel as much to get the wheels to turn a given distance It si important to lookout for problems so that you can take the necessary steps to repair or replace the desired part. A tight steering wheel indicates problem with the rack so adding more steering fluid could help easy the problem. Other problems likes grinding noises, burning oil smell are indications that you need to take immediate action. We easy your pain by carefully selecting the parts and making daily inventory check so that no cracked or leaked part ever reach you.</p>
                </div>
            </div>
        </div>
    </section> 
    <Assistant />
    <Brands />
    <Testimonial />
    <WhyChooseUs />
    <Footer />
    </>
  )
}

export default RackPinion