import React from 'react'
import Hand from '../images/handshake.png'
import Return from '../images/return-box.png'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'
import Call from '../sections/Call'


const Privacy = () => {
  return (
    <div>
        <Call />
        <Navbar />
         <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-left mb-5">
                    <h3 class="text-black">Privacy & Policy</h3>
                    <p>
                    Privacy Policy for SkyDriveAutoParts.com</p>

<p> 1. Introduction
At SkyDriveAutoParts.com ("we", "us", "our"), the privacy and security of our customers and visitors are of paramount importance. This Privacy Policy outlines how we manage and treat the personal data that we collect.
</p>
<p> 2. Data Collection We may collect, store, and process the following data: 
<li>a. Information related to any transactions carried out between you and us, including details of purchases.</li>
<li>b. Information that you provide for the purpose of registering or subscribing.</li>
<li>c. Information about your computer, including IP address, browser type, and geographical location.</li>
</p>

<p> 3. Use of Personal Data
Data collected is used for: 
<li>a. Administering the website.</li>
<li>b. Personalizing the website for users.</li>
<li>c. Enabling purchases and deliveries.</li>
<li>d. Sending marketing communications if you select to receive these.</li>
</p>
<p class="text-left"> 4. Data Disclosure
We might share your data with:
<li>a. Affiliates or business partners relevant to our services.</li>
<li>b. Legal entities when required by law.</li>



We will not provide your personal data to third parties for direct marketing.
</p>
<p> 5. Data Retention
Data is retained only for as long as necessary, consistent with the purpose for which it was collected, and in line with the necessary legal and operational retention periods.
d. Sending marketing communications if you select to receive these. </p>
<p> 6. Security
We employ technical and organizational measures to safeguard your data and prevent unauthorized access.
</p>
<p> 7. Cookies
Our website uses cookies. By using our website and consenting to this policy, you grant permission for the use of cookies per the terms in this policy.
</p>
<p> 8. Third Party Websites
The website may contain links to third-party websites. We are not responsible for the privacy policies or practices of third-party websites.
</p>
<p> 9. Your Rights
You may request details of personal information we hold about you, correction of incorrect data, or deletion of your data, subject to our retention requirements.
</p>
<p>10. Amendments
We may periodically update this policy. A new version will be published on our website. It is advisable to check this page occasionally to ensure you understand any changes.
</p> 
<p> 11. Contact
For questions or concerns related to this Privacy Policy, or to exercise any of your rights, please contact us at enquiry@skydriveautoparts.com.
</p>                  
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at +1(626) 699-9232 to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default Privacy