import React, { useState } from 'react';
import jsonData from './data.json';
import './Auto.css';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD6OcyQvQrVo3wAAzPHiLPGpIiig_giSuE",
    authDomain: "skydrive-2988d.firebaseapp.com",
    databaseURL: "https://skydrive-2988d-default-rtdb.firebaseio.com",
    projectId: "skydrive-2988d",
    storageBucket: "skydrive-2988d.appspot.com",
    messagingSenderId: "375357660476",
    appId: "1:375357660476:web:fdb75ec39d695885a45239"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const Search = () => {
    const [selectedYear, setYear] = useState("");
    const [selectedMake, setMake] = useState("");
    const [selectedModel, setModel] = useState("");
    const [selectedPart, setPart] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showContactInfo, setShowContactInfo] = useState(false);
    const navigate = useNavigate();

    const makeModelList = jsonData.find(item => item.name === "tbl_make_model_list" && item.type === "table");
    const makeData = makeModelList ? makeModelList.data.filter(item => item.type === "make") : [];
    const modelData = makeModelList ? makeModelList.data.filter(item => item.type === "model") : [];

    const masterDataList = jsonData.find(item => item.name === "tbl_master_data" && item.type === "table");
    const yearData = masterDataList ? masterDataList.data.filter(item => item.type === "year") : [];
    const partData = masterDataList ? masterDataList.data.filter(item => item.type === "part") : [];

    const handleSearchClick = () => {
        setShowContactInfo(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const selectedMakeName = makeData.find(make => make.id.toString() === selectedMake)?.name || "";
        const selectedModelName = modelData.find(model => model.id.toString() === selectedModel)?.name || "";

        const formData = {
            name: name,
            email: email,
            phone: phone,
            selectedYear: selectedYear,
            selectedMakeName: selectedMakeName,
            selectedModelName: selectedModelName,
            selectedPart: selectedPart
        };

        try {
            await push(ref(database, 'emails'), formData);
            console.log('Data saved successfully to Firebase.');
            navigate("/thankyou");
        } catch (error) {
            console.error('Error saving to Firebase:', error);
        }
    };

    return (
        <div className="App">
            <div className="search-wrapper">
            <form className="search-container" onSubmit={handleFormSubmit}>

                    {!showContactInfo ? (
                        <>
                            <select value={selectedYear} onChange={e => setYear(e.target.value)}>
                                <option value="">Select Year</option>
                                {yearData.map(year => <option key={year.id} value={year.label}>{year.label}</option>)}
                            </select>
                            <select value={selectedMake} onChange={e => { setMake(e.target.value); setModel(""); }}>
                                <option value="">Select Make</option>
                                {makeData.map(make => <option key={make.id} value={make.id}>{make.name}</option>)}
                            </select>
                            <select value={selectedModel} onChange={e => setModel(e.target.value)}>
                                <option value="">Select Model</option>
                                {modelData.filter(model => model.parent_id.toString() === selectedMake).map(filteredModel =>
                                    <option key={filteredModel.id} value={filteredModel.id}>{filteredModel.name}</option>
                                )}
                            </select>
                            <select value={selectedPart} onChange={e => setPart(e.target.value)}>
                                <option value="">Select Part</option>
                                {partData.map(part => <option key={part.id} value={part.label}>{part.label}</option>)}
                            </select>
                            <button type="button" onClick={handleSearchClick}>Search</button>
                        </>
                    ) : (
                        <>
                            <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" required />
                            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                            <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone Number" required />
                            <button type="submit">Submit</button>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Search;
