import React from 'react'
import Hand from '../images/handshake.png'
import Return from '../images/return-box.png'
import Footer from '../sections/Footer'
import Navbar from '../sections/Navbar'
import Call from '../sections/Call'


const Shipping = () => {
  return (
    <div>
        <Call />
        <Navbar />
        <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-left mb-5">
                    <h3 class="text-black">Shipping Policy</h3>
                    <p>All orders are shipped within 10-15 days. The carriers we use to deliver our orders are USPS, UPS & FedEx.</p>
<p>Order Tracking: If a tracking number is provided by the shipping carrier, we will update your order with the tracking information. Please note that some orders using 1st Class USPS mail will not have tracking numbers.</p>
<p>Shipping Rates: The rate charged for the shipping of your order is based on the weight of your products, and your location. Before the final checkout page you will be shown what the cost of shipping will be, and you will have a chance to not place your order if you decide not to.</p>
<p>Back Order: If an item goes on back order we will ship you the part of your order that is in stock. When the item becomes available we will ship you the rest of your order. You will not be charged any additional shipping and handling for the second shipment.</p>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Buy With Confidence</h5>
                        <p>Delivering reliable auto parts service since 2008</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>30 Days Returns</h5>
                        <p>Ordered the wrong part? Enjoy peace of mind with easy returns</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Return} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Call To Order</h5>
                        <p>Need help ordering? Call us toll free at +1(626) 699-9232 to order</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Fast Delivery</h5>
                        <p>Get the parts and accessories you need when you need them</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Huge Selection</h5>
                        <p>Huge selection of OEM, stock replacement or aftermarket accessories</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                    <div class="team-img">
                            <img src={Hand} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                        <h5>Competitive Prices</h5>
                        <p>We have the all the parts you need at the most competitive prices</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </div>
  )
}

export default Shipping